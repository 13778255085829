



















import { Vue, Component } from 'vue-property-decorator'
import EventList from '@/components/event/EventList.vue'
import store from '@/store'
import { Route } from '@/router'

@Component({
    components: {
        EventList,
    },
})
export default class EventView extends Vue {
    mounted() {
        store.dispatch.event.fetchEvents()
        store.dispatch.user.fetchUsers()
    }

    get events() {
        return store.getters.event.events
    }

    pushToEventWithId(id: string) {
        this.$router.push({ name: Route.EVENT_DETAILS, params: { eventId: `${id}` } })
    }
}
