




























import { Event } from '@/entities/event/Event'
import { trsl } from '@/i18n/lang'
import store from '@/store'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class EventList extends Vue {
    @Prop() events!: Event[]
    @Prop() height?: string
    @Prop() actionIcon?: string
    @Prop() showProperties?: string[]

    click(id: number) {
        this.$emit('select', id)
    }

    readonly allFields = [
        {
            key: 'id',
            label: trsl('input.property.id'),
            sortable: true,
        },
        {
            key: 'name',
            label: trsl('input.property.name'),
            sortable: true,
        },
        {
            key: 'contests',
            label: trsl('event.property.contests'),
            sortable: true,
        },
        {
            key: 'users',
            label: trsl('event.property.users'),
            sortable: true,
        },
        {
            key: 'action',
            label: '',
            tdClass: 'width-sm',
        },
    ]

    get fields() {
        if (!this.showProperties) return this.allFields
        const fields = []
        for (const key of this.showProperties) {
            fields.push(...this.allFields.filter(field => field.key == key))
        }
        return fields
    }

    get displayEvents() {
        return this.events.map(event => {
            const users = store.getters.user.usersAssignedToEventId(event.id)
            return {
                id: event.id,
                name: event.name,
                contests: event.contests.length,
                users: users.length,
            }
        })
    }
}
